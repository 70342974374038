import type { createAccordion } from '@/js/utils/accordion';

import { defineModule } from '@/js/utils/helpers';

const accordions: ReturnType<typeof createAccordion>[] = [];

const getElements = () => ({
  tabListElements: document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--media-accordion-tabs [role=tablist]',
  ),
  tabElements: document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--media-accordion-tabs [role=tab]',
  ),
  accordionElements: document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--media-accordion-tabs .accordion',
  ),
});

const handleTabClick = (e: Event) => {
  const currentTab = e.target as HTMLElement;

  const contentBlock = currentTab.closest('.content-block');
  if (!contentBlock) return;

  const [tabTriggers, tabContainer] = [
    contentBlock.querySelectorAll<HTMLElement>('[role=tab]'),
    contentBlock.querySelector<HTMLElement>('.tab__container'),
  ];
  if (!tabTriggers.length || !tabContainer) return;

  const firstTab = currentTab === tabTriggers.item(0);

  tabContainer.classList.toggle('-translate-x-1/2', !firstTab);

  tabTriggers.forEach((item) => {
    const isCurrent = item === currentTab;
    item.setAttribute('aria-selected', String(isCurrent));
    item.setAttribute('tabindex', isCurrent ? '-1' : '0');
  });
};

export default defineModule(
  async () => {
    const { tabElements, accordionElements } = getElements();
    if (!tabElements || !accordionElements) return;

    tabElements.forEach((tab) =>
      tab.addEventListener('click', handleTabClick, { passive: true }),
    );

    const { createAccordion } = await import('@/js/utils/accordion');
    if (!accordionElements.length) return;

    accordionElements.forEach((accordionElement) => {
      const accordion = createAccordion(accordionElement, {
        onToggle: (open) => {
          if (!open) return;

          const contentBlock = accordionElement.closest('.content-block');

          accordions.forEach((otherAccordion) => {
            if (
              (contentBlock &&
                otherAccordion.el.closest('.content-block') !== contentBlock) ||
              accordionElement === otherAccordion.el
            )
              return;

            otherAccordion.close();
          });
        },
      });

      accordions.push(accordion);
    });
  },
  () => {
    const { tabElements } = getElements();
    tabElements.forEach((tab) =>
      tab.removeEventListener('click', handleTabClick),
    );

    while (accordions.length) {
      accordions.pop()?.destroy();
    }
  },
);
